import React, { useState, useCallback, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { RoutineContext } from './contexts/RoutineContext';
import { UserContext } from './contexts/UserContext'
import { UIContext } from './contexts/UIContext'
import axios from 'axios';

import './App.css';
import msft from '../src/assets/MS_Startups_Celebration_Badge_Dark.png'


import Login from './pages/Login/Login';
import Signup from './pages/SignUp/SignUp';
import NavBar from './components/NavBar/NavBar';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import InterestsPage from './pages/InterestsPage/InterestsPage';
import GeneratedStryds from './pages/GeneratedStryd/GeneratedStryds';
import Home from './pages/Home/Home'
import RoutinePage from './pages/RoutinePage/RoutinePage'
import ChoicePage from './pages/ChoicePage/ChoicePage';
import AddRoutinePage from './pages/AddRoutinePage/AddRoutinePage';
import ExploreRoutines from './pages/ExploreRoutines/ExploreRoutines';
import People from './pages/People/People';
import UserProfile from './pages/UserProfile/UserProfile';
import UpgradePage from './pages/UpgradePage/UpgardePage';
import MealPlans from './pages/MealPlans/MealPlans';
import ScrollToTop from './utils/scrollToTop';
import { toastConfig } from 'react-simple-toasts';
import AudioPlayer from './utils/audioPlayer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BottomNav from './components/BottomNav/BottomNav';

import {
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';

import {
	useUser,
} from "@clerk/clerk-react";
import ProfilePage from './pages/ProfilePage/ProfilePage';
import PlanConfirmation from './pages/PlanConfirmation/PlanConfirmation';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
	clientId: process.env.REACT_APP_PAYPALID,
	vault: true,
	intent: "subscription"
};


function App() {
	const { user } = useUser();
	const API_URL = process.env.REACT_APP_API_URL;


	const [currentXP, setCurrentXP] = useState(0);
	const [currentProgress, setCurrentProgress] = useState(0);
	const [dbUser, setDbUser] = useState({})
	const [totalXP, setTotalXP] = useState(0)
	const [globalUIState, setGlobalUIState] = useState({
		screenWidth: "none",
		currentSection: "app", // Fixed typo in `currentSection`
	});
	const getCurrentYear = () => new Date().getFullYear();
	const currentYear = getCurrentYear();

	const getUser = useCallback(() => {
		if (user) {
			axios.get(`${API_URL}/users/${user.id}`)
				.then(res => {
					// console.log('get user', res.data[0]); // Log the user data
					setDbUser(res.data[0]); // Then set the state
				})
				.catch(err => console.error(err.message)); // Log any errors
		}
	}, [user, API_URL, setDbUser]); // Dependencies array includes all variables from the outer scope that are used in the callback

	useEffect(() => {
		// Function to update screen width
		const updateScreenWidth = () => {
			console.log('window screen width:', window.innerWidth);
			setGlobalUIState((prevState) => ({
				...prevState,
				screenWidth: window.innerWidth,
			}));
		};

		// Function to set the initial section to "app"
		const initializeCurrentSection = () => {
			console.log('Initializing current section to "app"');
			setGlobalUIState((prevState) => ({
				...prevState,
				currentSection: "app",
			}));
		};

		// Set the initial screen width and current section
		updateScreenWidth();
		initializeCurrentSection();

		// Add event listener for window resize
		window.addEventListener("resize", updateScreenWidth);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateScreenWidth);
		};
	}, []);




	useEffect(() => {
		if (user) {
			getUser()
			// addUserToDatabase();
			console.log(user.profileImageUrl);
		}
		console.log(dbUser)
	}, [
		// addUserToDatabase
		, user]); // Dependencies



	return (
		<div className="App">
			<PayPalScriptProvider options={initialOptions}>
				<div className='pages'>
					<Router>
						<UIContext.Provider
							value={
								{ globalUIState, setGlobalUIState }
							}>
							<UserContext.Provider value={
								{ dbUser, setDbUser }
							}>
								<ScrollToTop />
								<RoutineContext.Provider value={{
									currentXP,
									setCurrentXP,
									totalXP,
									setTotalXP,
									currentProgress,
									setCurrentProgress
								}}>
									<NavBar />
									<Routes>
										<Route element={<Home />} path='/' />
										<Route element={<WelcomePage />} path='welcome' />
										<Route element={<Login />} path='login' />
										<Route element={<Signup />} path='signup' />
										<Route element={<InterestsPage />} path='interests' />
										<Route element={<ChoicePage />} path='choice' />
										<Route element={<GeneratedStryds />} path='generated-stryds' />
										<Route element={<RoutinePage />} path='stryd/:userID/:routineName' />
										<Route element={<AddRoutinePage />} path='add-routine' />
										<Route element={<ProfilePage />} path='profile-page' />
										<Route element={<ExploreRoutines />} path='explore-routines' />
										<Route element={<People />} path='people' />
										<Route element={<UserProfile />} path='profile/:userID' />
										<Route element={<UpgradePage />} path='upgrade' />
										<Route element={<PlanConfirmation />} path='plan-confirm' />
										<Route element={<MealPlans />} path='meals' />
									</Routes>
									<BottomNav />
								</RoutineContext.Provider>
							</UserContext.Provider>
						</UIContext.Provider>
					</Router>

					<footer className='Footer'>
						<span className='FooterText FooterContent'>
							<span>
								Say hi:
							</span>
							<p></p>
							<a href="mailto:hello@calcium.fitness" className='FooterLink'>hello@calcium.fitness</a>
							<a href="mailto:emmanuel@calcium.fitness" className='FooterLink'>emmanuel@calcium.fitness</a>
						</span>

						<div className='CompanyInfoWrapper'>
							<div>
								<span className="FooterText FooterDate">{currentYear} </span>
								<a
									href="https://kin3tic.tech"
									target="_blank"
									rel="noopener noreferrer"
									className='ProjectName'>
									Project Kinetic
								</a>
							</div>
							<span className='CompanyName'>
								By Engima Works LLC. RC-7809021
							</span>
							<p></p>
							<img src={msft} alt="Microsoft for startups logo" className='FooterImage' />
						</div>
					</footer>

				</div>

				<Analytics />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="dark"
				/>
			</PayPalScriptProvider>
		</div>
	);
}

export default App;