import React, { useEffect, useState, useContext } from 'react';
import toast, { toastConfig } from 'react-simple-toasts';
import spinner from '../../assets/Spinner.svg';
import cupIcon from '../../assets/cupIcon.svg';
import copyIcon from '../../assets/copyIcon.svg';
import RoutineInvite from '../../components/RoutineInvite/RoutineInvite';
import Modal from '../../components/Modal/Modal'

import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import {
	useUser,
} from "@clerk/clerk-react";
import { RoutineContext } from '../../contexts/RoutineContext';
import RoutineMemberCard from '../../components/RoutineMemberCard/RoutineMemberCard';

import style from './RoutinePage.module.css'
import ChallengeCard from '../../components/ChallengeCard/ChallengeCard';
import FriendCard from '../../components/FriendCard/FriendCard';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import CopyButton from '../../components/CopyButton/CopyButton';
import Timer from '../../components/Timer/Timer';
import RoutineCompletedModal from '../../components/RoutineCompleteModal/RoutineCompleteModal';
import LeaderBoardsModal from '../../components/LeaderBoardsModal/LeaderBoardsModal';
import { useNavigate } from 'react-router-dom';
import done from '../../assets/done.mp3'


const RoutinePage = () => {
	const { userID, routineName } = useParams();
	const { user } = useUser();
	const [routine, setRoutine] = useState({});
	const [routineUser, setRoutineUser] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [isLeaderboardsOpen, setIsLeaderboardsOpen] = useState(false);
	const navigate = useNavigate()


	const {
		currentXP,
		totalXP,
		setCurrentXP,
		setCurrentProgress,
		currentProgress
	} = useContext(RoutineContext)

	const API_URL = process.env.REACT_APP_API_URL;
	toastConfig({ theme: 'dark' });

	const getRoutines = () => {
		if (user) {
			axios.get(`${API_URL}/stryds/${userID}/${routineName}`)
				.then(res => setRoutine(res.data))
				.catch(err => err.message)
		}
	}

	const getUser = () => {
		if (user) {
			axios.get(`${API_URL}/users/${userID}`)
				.then(res => setRoutineUser(res.data[0]))
				.catch(err => err.message)
		}
	}

	const joinRoutine = async (routineCreatorID, routineName, userID, username, profileImageLink) => {
		toast('joining routine')
		// Create the user object before sending the request
		const userToAdd = {
			userID: userID,
			username: username,
			profileImageLink: profileImageLink,
			userRoutineXP: 0
			// Add other data needed for joining the routine
		};

		const routineToAdd = {
			routineName: routine.routineName,
			estimatedTimeMinutes: routine.estimatedTimeMinutes,
			routineCreator: routineUser.username,
			routineCreatorUserID: routineUser.userID
		}

		setRoutine(prevRoutine => {
			return {
				...prevRoutine,
				participants: [...prevRoutine.participants, userToAdd]
			};
		});

		try {
			const response = await axios.post(
				`${API_URL}/stryds/join-routine/${routineCreatorID}/${routineName}`,
				userToAdd
			);

			const addJoinedRoutineResponse = await axios.post(`${API_URL}/stryds/joined-routines/${user.id}`, routineToAdd
			)

			console.log(addJoinedRoutineResponse.data);
			console.log('add joined routine')
			console.log(response.data); // Handle successful response
			toast('joined routine')
			const emailResponse = await axios.post(`${API_URL}/emails/send-email`, {
				to: routineUser.email,       // Recipient's email address
				from: 'hello@calcium.fitness',    // Your (or sender's) email address
				subject: `Someone joined ${routineName}`, // Subject line of the email
				html:
					`<h4>Hi, ${routineUser.username}.</h4>
				<p>${user.username} just joined you in ${routineName}.</p>
				<p>Cheers!</p>
				<p>Your friends at Calcium.</p>
				`// HTML content of the email
			});
			console.log(emailResponse.data);
			console.log('Email notification sent');
			return response.data;
		} catch (error) {
			console.error(error.response.data); // Handle error response
			console.error(error.addJoinedRoutineResponse.data);
			toast('an error occured while joining routine')
			// Revert the UI changes if the request fails
			setRoutine(prevRoutine => {
				return {
					...prevRoutine,
					participants: prevRoutine.participants.filter(
						participant => participant.userID !== userID
					)
				};
			});

			throw new Error(error.response.data);
		}
	};



	const leaveRoutine = async (routineCreatorID, routineName, userID) => {
		toast('leaving routine');
		const removeUserFromRoutine = (userIDToRemove) => {
			setRoutine(prevRoutine => {
				const updatedParticipants = prevRoutine.participants.filter(
					participant => participant.userID !== userIDToRemove
				);

				return {
					...prevRoutine,
					participants: updatedParticipants,
				};
			});
		};

		try {
			removeUserFromRoutine(userID);
			const response = await axios.post(
				`${API_URL}/stryds/leave-routine/${routineCreatorID}/${routineName}`,
				{
					userID: userID,
					// Add other data needed for leaving the routine
				}
			);

			const removeRoutineResponse = await axios.delete(
				`${API_URL}/stryds/remove-routine/${userID}/${routineName}`,
			)

			// Assuming routinesData contains the fetched routines
			const updatedRoutines = routine.map(routine => {
				if (routine.creatorID === routineCreatorID && routine.name === routineName) {
					return {
						...routine,
						participants: routine.participants.filter(participant => participant.userID !== userID)
					};
				}
				return routine;
			});

			// Update the routines data with the updatedRoutines
			setRoutine(updatedRoutines);
			console.log(response.data); // Handle successful response

			toast('removed routine');
			console.log(removeRoutineResponse.data);
			// Display the toast after the state has been updated
			toast('left routine');
			return response.data;

		} catch (error) {
			console.error(error.removeUserFromRoutine.data)

			console.error(error.response.data); // Handle error response
			toast('an error occured while leaving routine')
			throw new Error(error.response.data);
		}
	};



	const syncRoutineXP = () => {

	}

	const syncTotalXP = () => {

	}


	useEffect(() => {
		if (user) {
			getRoutines()
			getUser()
		}
		return () => {
			setCurrentXP(0)
			setCurrentProgress(0)
		}
	}, [user])

	useEffect(() => {
		if (currentProgress >= 100) {
			// Play the done sound
			const audio = new Audio(done);
			audio.play().catch(error => console.error('Error playing sound:', error));
	
			handleOpenModal();
		}
	}, [currentXP]);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOpenLeaderboardModal = () => {
		setIsLeaderboardsOpen(true)
	}

	const handleCloseLeaderboardModal = () => {
		setIsLeaderboardsOpen(false)
	}

	const RoutineComplete = () => {
		// Display complete alert
		handleOpenModal()
		// POST request to update the XP n the user profile.
	}

	const copyLinkHandler = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			console.log('Content copied to clipboard');
			toast('Copied Invite Link 📝')
		} catch (err) {
			console.error('Failed to copy: ', err);
		}
	}

	return (
		user ?
			<div>
				{/* <Modal onClose={handleCloseModal}>
					<RoutineInvite />
				</Modal> */}

				{/* Render the RoutineCompletedModal only when the modal is open */}
				{isModalOpen && currentProgress === 100 && (
					<RoutineCompletedModal
						onClose={handleCloseModal}
						xp={currentXP}
						workoutName={routine.routineName}
					/>)}


				{
					isLeaderboardsOpen && (
						<LeaderBoardsModal
							onClose={handleCloseLeaderboardModal}
							people={routine.participants}
						/>
					)
				}

				<div className={style.RoutinePageWrapper}>
					<div className={style.SectionWrapper}>
						<div className={style.RoutineInfoWrapper}>
							<div className={style.RoutineNameWrapper}>
								<span className={style.RoutineName}>{routine.routineName}</span>

								<Link
									to={`/profile/${userID}`}
									className={style.RoutineCreator}
								>
									<span>Created by {routineUser.username ? routineUser.username : 'loading'}</span>
								</Link>
							</div>

							<span className={style.RoutineInfo}>Estimated completion time: {routine.estimatedTimeMinutes || routine.duration} mins</span>

							<div className={style.RoutineDetailsWrapper}>
								<div className={style.RoutineProgressWrapper}>
									<span className={style.RoutineProgress}>
										{currentProgress.toFixed(1)}% completed
									</span>

									<ProgressBar progress={currentProgress} />
								</div>

								<div className={style.RoutineStatsWrapper}>
									<Timer />

									<div className={style.RoutineXPWrapper}>
										<img
											src={cupIcon}
											alt=""
											className={style.RoutineXPIcon}
										/>

										<span className={style.RoutineStatText}>
											{currentXP} XP
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className={style.ChallengeCardsWrapper}>
							{
								<div className={style.ChallengeCardsWrapper}>
									{routine && routine.workouts && routine.workouts.length > 0 ?
										routine.workouts.map(challenge => <ChallengeCard
											workoutName={challenge.workoutType}
											workoutSets={challenge.workoutSets}
											workoutReps={challenge.workoutReps}
											percentagePoints={100 / (routine.workouts.length * challenge.workoutSets)}
										/>)
										:
										<img src={spinner} alt="" />
									}
								</div>
							}
						</div>
					</div>


					<div>
						<div className={style.RoutineActionsWrapper}>
							{/* <PrimaryButton
								text='Invite Friends'
							/> */}

							{userID !== user.id && routine && routine.participants ? (
								<>
									{routine.participants.some(participant => participant.userID === user.id) ? (
										<PrimaryButton
											text={'Leave'}
											click={() =>
												leaveRoutine(userID, routineName, user.id)
													.then((data) => {
														// Handle success
														console.log('Successfully left the routine:', data);
														// Optionally update UI or state to reflect the change
													})
													.catch((error) => {
														// Handle error
														console.error('Error leaving the routine:', error.message);
													})
											}
										/>
									) : (
										<PrimaryButton
											text={'Join'}
											click={() => joinRoutine(userID, routineName, user.id, user.username, user.profileImageUrl)
												.then((data) => {
													// Handle success
													console.log('Successfully joined the routine:', data);
												})
												.catch((error) => {
													// Handle error
													console.error('Error joining the routine:', error.message);
												})
											}
										/>
									)}
								</>
							) : null}



							<button
								className={style.CopyButton}
								onClick={() => copyLinkHandler(`https://www.calcium.fitness/stryd/${userID}/${routineName}`)}
							>
								<img src={copyIcon} alt="" />
							</button>

						</div>



						<span className={style.RoutineSectionHeading}>People</span>
						<div className={style.FriendCardWrapper}>
							<div className={style.RoutineLeaderBoardWrapper}>
								{routine.participants && routine.participants.length > 0 ? (
									[
										// Display the specified user first
										routine.participants.find(participant => participant.userID === user.id) && (
											<Link to={`/profile/${user.id}`} key={`user-${user.id}`}>
												<RoutineMemberCard
													image={routine.participants.find(participant => participant.userID === user.id).profileImageLink}
													name={user.username}
													xp={
														routine.participants.find(participant => participant.userID === user.id).userRoutineXP + currentXP
													}
												/>
											</Link>
										),
										// Then display other participants, limited to 4 (so total 5 including the user if present)
										...routine.participants
											.filter(participant => participant.userID !== user.id)
											.sort((a, b) => b.userRoutineXP - a.userRoutineXP)
											.slice(0, 4) // Limit to the first 4 participants
											.map(participant => (
												<Link to={`/profile/${participant.userID}`} key={participant.userID}>
													<RoutineMemberCard
														image={participant.profileImageLink}
														name={participant.username}
														xp={participant.userRoutineXP}
													/>
												</Link>
											))
									]
								) : (
									<p>No participants found</p>
								)}
							</div>



							<SecondaryButton
								text='View leaderboards'
								click={() => handleOpenLeaderboardModal()}
							/>


							{
								userID === user.id ?

									<button className={style.DeleteButton}
										onClick={() => {
											if (window.confirm("Are you sure you want to delete this routine? This action cannot be undone.")) {
												toast('Deleting routine...');
												axios.delete(`${API_URL}/stryds/delete/${userID}/${routineName}`)
													.then(res => {
														if (res.status === 200) {
															toast('Routine deleted');
															navigate('/profile-page');
														} else {
															toast('Failed to delete routine');
														}
														console.log(res);
													})
													.catch(err => {
														console.log('Error:', err.message);
														toast('An error occurred while deleting the routine');
													});
											}
										}}
									>
										Delete
									</button>

									: null
							}

						</div>
					</div>
				</div>
			</div>
			:
			<p>
				Login to view this page
			</p>
	)

}


export default RoutinePage