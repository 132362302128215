import React, { useState, useContext } from 'react';
import style from './WorkoutCard.module.css';
import checkOff from '../../assets/checkOff.svg';
import checkOn from '../../assets/checkOn.svg';
import { RoutineContext } from '../../contexts/RoutineContext';
import toast, { toastConfig } from 'react-simple-toasts';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
	useUser,
} from "@clerk/clerk-react";
import { UserContext } from '../../contexts/UserContext';
import { checkSubscription } from '../../utils/checkSubscription';
import cling from '../../assets/cling.mp3'

const WorkoutCard = props => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { user } = useUser();


	const [checked, setChecked] = useState(false)
	const { setCurrentXP, currentXP, currentProgress, setCurrentProgress } = useContext(RoutineContext)

	const { userID, routineName } = useParams();
	const { dbUser, setDbUser } = useContext(UserContext);

	const incrementRoutineXP = async (routineCreatorID, routineName, userID) => {
		const XP_INCREMENT = 10; // Set the XP increment value

		try {
			const response = await axios.post(
				`${API_URL}/stryds/increment-routine-xp/${routineCreatorID}/${routineName}`,
				{
					userID: userID,
					userRoutineXP: XP_INCREMENT
				}
			);

			console.log(response.data); // Handle success response
			return response.data;
		} catch (error) {
			console.error('Error incrementing routine XP:', error.response.data); // Handle error response
			throw new Error(error.response.data);
		}
	};


	const decrementRoutineXP = async (routineCreatorID, routineName, userID) => {
		const XP_DECREMENT = 10; // Set the XP decrement value

		try {
			const response = await axios.post(
				`${API_URL}/stryds/decrement-routine-xp/${routineCreatorID}/${routineName}`,
				{
					userID: userID,
					userRoutineXP: XP_DECREMENT
				}
			);

			console.log(response.data); // Handle success response
			return response.data;
		} catch (error) {
			console.error('Error decrementing routine XP:', error.response.data); // Handle error response
			throw new Error(error.response.data);
		}
	};

	const incrementTotalXP = async (userID, userXP) => {
		try {
			const response = await axios.post(
				`${API_URL}/users/increment-total-xp/${userID}`,
				{
					userXP: userXP
				}
			);

			console.log(response.data); // Handle success response
			return response.data;
		} catch (error) {
			console.error('Error incrementing total XP:', error.response.data); // Handle error response
			throw new Error(error.response.data);
		}
	};


	const decrementTotalXP = async (userID, userXP) => {
		try {
			const response = await axios.post(
				`${API_URL}/users/decrement-total-xp/${userID}`,
				{
					userXP: userXP
				}
			);

			console.log(response.data); // Handle success response
			return response.data;
		} catch (error) {
			console.error('Error decrementing total XP:', error.response.data); // Handle error response
			throw new Error(error.response.data);
		}
	};


	const handleCardClick = async () => {
		// Play the cling sound
		const audio = new Audio(cling);
		audio.play().catch(error => console.error('Error playing sound:', error));
		
		// Perform subscription check using the dbUser object
		const hasActiveSubscription = await checkSubscription(dbUser);
		console.log(hasActiveSubscription)

		setChecked(!checked); // Toggle checked state immediately for UI feedback
		console.log('Card interaction:', userID, routineName, checked ? "unchecked" : "checked");

		// Define XP increment/decrement value
		const XP_CHANGE = 10;

		// Proceed with XP and progress update logic
		if (hasActiveSubscription) {
			if (checked) {
				toast('Updating XP...');
				setCurrentXP(currentXP - XP_CHANGE);

				const progressToSubtract = (props.percentagePoints / 100) * 100;
				setCurrentProgress(currentProgress - progressToSubtract);

				try {
					await decrementRoutineXP(userID, routineName, user.id);
					await decrementTotalXP(userID, XP_CHANGE);
					toast('XP Decremented');
				} catch (error) {
					console.error('Error updating XP:', error);
					toast('Error updating XP');
				}
			} else {
				// Play the cling sound
				const audio = new Audio(cling);
				audio.play().catch(error => console.error('Error playing sound:', error));

				toast('Updating XP...');
				setCurrentXP(currentXP + XP_CHANGE);

				const newProgress = currentProgress + props.percentagePoints;
				const cappedProgress = Math.min(100, parseFloat(newProgress.toFixed(1)));
				setCurrentProgress(cappedProgress);

				try {
					await incrementRoutineXP(userID, routineName, user.id);
					await incrementTotalXP(user.id, XP_CHANGE);
					toast('XP Incremented');
				} catch (error) {
					console.error('Error updating XP:', error);
					toast('Error updating XP');
				}
			}
			return; // Exit the function early if the user doesn't have an active subscription
		} else {
			toast('Subscription required to update XP.');
		}
	};



	return (
		<div className={style.WorkoutsCardWrapper}>
			<div
				className={!checked ? style.WorkoutCard : style.WorkoutCardSelected}
				onClick={handleCardClick}
			>
				<div className={style.WorkoutInfo}>
					<span className={style.WorkoutName}>{props.name}</span>
					<span className={style.WorkoutFrequency}>{props.reps}</span>
				</div>
				<img src={checked ? checkOn : checkOff} alt="" className={style.CheckIcon} onClick={handleCardClick} />
			</div>
		</div>
	)
}



export default WorkoutCard;